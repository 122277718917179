:root {
  --primary: #6929ca;
  --primary-light: #8535ff;
  --primary-text: #333;
  --secondary-text: #333333;
  --bgcolor: #fff;
  --off-white: #d4d4d4;
  --chat-icon-distance: 100px;

  /* --primary: #7582eb; */
  /* --primary-text: #ffffff; */
  /* --bg-color: 'rgb(17, 24, 39)' */
  /* --secondary-text: #ffffff20; */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bgcolor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-container {
  display: flex;
  gap: 10%;
  margin-left: auto;
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .btn-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 599px) {
  :root {
    --chat-icon-distance: 55px;
  }
}

.cc {
  background: var(--bgcolor);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meet-code-input {
  padding: 0.8rem;
  font-size: 1.5rem;
  border-radius: 0.5em;
  outline: none;
  border: none;
  max-width: 80vw;
  width: 500px;
}

.meet-code-input:disabled {
  color: #fffffff0;
}

.join-button {
  font-size: 1.5rem;
  border: none;
  background-color: #7a8194;
  color: white;
  border-radius: 1rem;
  padding: 10px 30px;
  margin-top: 10px;
  cursor: pointer;
}

.google-sign-in {
  margin-top: 20px;
  cursor: pointer;
  align-self: center;
  /* height: 60px; */
}

.text-type1 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4043;
  margin-top: 8px;
}

#chat-widget {
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.6s;
}
